/* eslint-disable react/no-array-index-key */
/* eslint-disable no-useless-escape */
import { FC } from 'react';
import { unEscapeMarkdown } from './utils';
import { TMarkdownToHtmlConverterProps } from './types';

export const MarkdownToHtmlConverter: FC<TMarkdownToHtmlConverterProps> = ({ markdownText }) => {
	const boldRegex = /(\*{1}[^*]+\*{1})/g;
	// const underlineRegex = /(=={1}[^_]+=={1})/g;
	const italicRegex = /(\_{2}[^_]+\_{2})/g;
	const strikethroughRegex = /(~{1}[^~]+~{1})/g;
	const imageRegex = /(!\<[^\]]+\]\([^)]+\))/g;
	const linkRegex = /(\[[^\]]+\]\([^)]+\))/g;
	const spoilerRegex = /(\|{2}[^|]+\|{2})/g;
	const lineTranslation = /(\r\n|\n|\r)/gm;
	const headingRegex = /^(#{1,6})\s(.*)/gm;

	const escapedMarkdown = unEscapeMarkdown(markdownText)?.replace(/[<>&]/g, (tag: string) => {
		const tagsToReplace = (t: string) => {
			if (t === '<') return '&lt;';
			if (t === '>') return '&gt;';
			if (t === '&') return '&amp;';
			return t;
		};
		return tagsToReplace(tag);
	});

	const replaceUnderline = (text: string) => {
		// return text.replace(/__{1}([^_]+)__{1}/g, '==$1==');
		return text;
	};

	const replaceLineTranslation = (text: string) => {
		return text?.replace(/(\r\n|\n|\r)/gm, '<br />');
	};

	const parts = replaceUnderline(escapedMarkdown?.replaceAll('![', '!<'))
		// ?.split(new RegExp(imageRegex.source, 'g'))
		?.split(boldRegex)
		?.flat()
		?.map((part) => part?.split(italicRegex))
		?.flat()
		// ?.map((part) => part?.split(underlineRegex))
		// ?.flat()
		?.map((part) => part?.split(strikethroughRegex))
		?.flat()
		?.map((part) => part?.split(imageRegex))
		?.flat()
		?.map((part) => part?.split(linkRegex))
		?.flat()
		?.map((part) => part?.split(spoilerRegex))
		?.flat()
		?.map((part) => part?.split(headingRegex))
		?.flat()
		?.map((part) => part?.split(lineTranslation))
		?.flat();
	// const parts = replaceUnderline(escapedMarkdown)?.split(
	// 	new RegExp(
	// 		`(${imageRegex.source}|${linkRegex.source}|${italicRegex.source}|${boldRegex.source})`,
	// 		'g',
	// 	),
	// );

	return (
		<div style={{ lineHeight: 1.8 }}>
			{parts?.map((part, index) => {
				if (part?.match(imageRegex)) {
					const link = part?.match(/\([^\)]+\)/g);
					const text = part?.match(/!\[\[[^\]]+\]/g);
					return (
						<img
							style={{
								margin: '20px',
								maxWidth: '1000px',
								width: 'calc(100% - 40px)',
								// margin: 'auto',
								// display: 'block',
							}}
							src={link ? link[0]?.replace(/\(|\)/g, '') : ''}
							alt="img"
						/>
					);
				}
				if (part?.match(boldRegex)) {
					return <b key={`parts${index}`}>{part?.replace(/\*{1}/g, '')}</b>;
				}
				// if (part?.match(underlineRegex)) {
				// 	return <u key={`parts${index}`}>{part.replace(/=={1}/g, '')}</u>;
				// }
				if (part?.match(italicRegex)) {
					return <i key={`parts${index}`}>{part?.replace(/_{2}/g, '')}</i>;
				}
				if (part?.match(strikethroughRegex)) {
					return <s key={`parts${index}`}>{part?.replace(/~{1}/g, '')}</s>;
				}
				if (part?.match(linkRegex)) {
					const link = part?.match(/\([^\)]+\)/g);
					const text = part?.match(/\[[^\]]+\]/g);
					return (
						<>
							{` `}
							<a key={`parts${index}`} href={link ? link[0]?.replace(/\(|\)/g, '') : ''}>
								{text ? text[0]?.replace(/\[|\]/g, '') : ''}
							</a>
						</>
					);
				}

				if (part?.match(spoilerRegex)) {
					return (
						<span key={`parts${index}`} className="code-block--code">
							{part?.replace(/\|{2}/g, '')}
						</span>
					);
				}
				if (part?.startsWith('#')) {
					const heading = part?.match(/^(#{1,6})/g);
					const hashes = part?.match(/#/g)?.length ?? 0;
					const text = part?.replace(/#/g, '').trim();
					return (
						<h1 key={`parts${index}`} className={`heading-${hashes}`}>
							{text}
						</h1>
					);
				}
				if (part?.match(lineTranslation)) {
					return <br key={`parts${index}`} />;
				}

				return part;
			})}
		</div>
	);
};
