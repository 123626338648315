import { FC, useState } from 'react';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import DoneIcon from '@mui/icons-material/Done';
import { IconButton } from '@mui/material';
import { TApiAlertProps } from './types';

export const ApiAlert: FC<TApiAlertProps> = ({ method, endpoint }) => {
	const methodSmall = method?.toLowerCase();
	const [copy, setCopy] = useState(false);
	const copyToClipboard = () => {
		navigator.clipboard
			.writeText(endpoint)
			.then(() => {
				console.log('copied');
				setCopy(true);
				setTimeout(() => {
					setCopy(false);
				}, 2000);
			})
			.catch((err) => {
				console.log('Something went wrong', err);
			});
	};

	return (
		<div
			style={{
				// width: '100%',
				borderRadius: '3rem 0 0 3rem',
				// backgroundColor: '#038fe1',
				padding: '0 20px',
				// margin: '20px -10px 0 5px',
				marginRight: '-10px',
				display: 'flex',
				justifyContent: 'space-between',
				alignItems: 'center',
				height: '3.6rem',
				// position: 'sticky',
				top: '0',
				zIndex: 1000,
			}}
			className={`api-alert--container api-alert-${methodSmall}--container`}
		>
			<h2
				className={`api-body--header api-body--header-${methodSmall}`}
				style={{ margin: 0, position: 'relative', color: '#fff', fontSize: '1.6rem' }}
			>
				<span
					className={`api-method api-method-${methodSmall}`}
					style={{ bottom: '0px', position: 'absolute' }}
				/>
				<span className="api-body--header--url" style={{ marginLeft: '70px' }}>
					{endpoint}
				</span>
			</h2>
			{copy ? (
				<DoneIcon sx={{ color: 'white' }} />
			) : (
				<IconButton
					onClick={(e) => {
						e.preventDefault();
						e.stopPropagation();
						copyToClipboard();
					}}
				>
					<ContentCopyIcon sx={{ color: 'white' }} />
				</IconButton>
			)}
		</div>
	);
};
