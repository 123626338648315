import { FC, useEffect, useState } from 'react';
import { MarkdownToHtmlConverter } from 'ui/MarkdownToHtmlConverter';
import { Accordion, AccordionDetails, AccordionSummary } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { TRequestBodyItemProps } from './types';

export const RequestBodyItem: FC<TRequestBodyItemProps> = ({
	name,
	type,
	required,
	description,
	example,
	expandedAll,
}) => {
	const [expanded, setExpanded] = useState<boolean>(expandedAll);
	useEffect(() => {
		setExpanded(expandedAll);
	}, [expandedAll]);

	return (
		<Accordion
			sx={{
				width: '100%',
				border: 'none',
				boxShadow: 'none',
				margin: 0,
				padding: 0,
				minHeight: 0,
			}}
			className="code-block--body"
			expanded={expanded}
			onChange={() => setExpanded(!expanded)}
			disableGutters
		>
			<AccordionSummary
				expandIcon={<ExpandMoreIcon />}
				aria-controls="panel1a-content"
				id="panel1a-header"
				sx={{
					margin: 0,
					padding: 0,
					minHeight: 0,
				}}
				style={{ minHeight: 0, margin: '0 0 -10px 0' }}
			>
				<div>
					<div className="code-block--body--var" style={{ fontWeight: 'bold' }}>
						{name}
						<i
							style={{
								color: '#666',
								// backgroundColor: '#0001',
								padding: '2px 10px',
								fontWeight: 'normal',
								fontSize: '1.1rem',
							}}
						>
							{type}
						</i>

						{required && (
							<sup
								style={{
									color: '#fe4e4e',
									marginLeft: '5px',
								}}
							>
								required
							</sup>
							// uppercase
						)}
					</div>
					{/* <div
						className="code-block--body--type"
						style={{
							textAlign: 'right',
						}}
					>
						<span
							style={{
								color: '#666',
								// backgroundColor: '#0001',
								padding: '2px 10px',
								borderRadius: '10px',
							}}
						>
							{type}
						</span>
					</div> */}
				</div>
			</AccordionSummary>
			<AccordionDetails
				sx={{
					border: 'none',
					padding: 0,
					margin: '0 0 0 1.2rem',
				}}
			>
				<div className="code-block--body--description">
					{MarkdownToHtmlConverter({ markdownText: description })}
					{/* {description} */}
				</div>
				{(type === 'array of objects' || type === 'object') && (
					<div
						className="code-block--body--content--item"
						style={{
							// marginLeft: '1.2rem',
							marginTop: '10px',
							backgroundColor: '#0000000b',
							padding: '10px',
							borderRadius: '10px',
							fontSize: '1.2rem',
						}}
					>
						{example?.map?.((subItem: any) => (
							<RequestBodyItem
								key={subItem.name}
								name={subItem.name}
								type={subItem.type}
								required={subItem.required}
								description={subItem.description}
								example={subItem.example}
								expandedAll={expandedAll}
							/>
						)) ?? ''}
					</div>
				)}
			</AccordionDetails>
		</Accordion>
	);
};
