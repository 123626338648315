/* eslint-disable react/no-array-index-key */
import { FC } from 'react';
import { divide } from 'lodash';
import { TRequestBodyExampleItemProps } from './types';
import { StringValue } from './StringValue';
import { NumberValue } from './NumberValue';

export const RequestBodyExampleItem: FC<TRequestBodyExampleItemProps> = ({
	name,
	type,
	required,
	description,
	example,
	comma,
}) => {
	return (
		<div
			className="code-block-example--body"
			style={{ marginBottom: '5px', marginLeft: '20px', fontSize: '1rem' }}
		>
			<div style={{ display: 'flex' }}>
				<div
					className="code-block-example--body--var"
					style={{ marginRight: '10px', color: '#c85b63' }}
				>
					&quot;{name}&quot;:
				</div>
				{type === 'string' && (
					<>
						<StringValue value={example} />
						{comma && ','}
					</>
				)}
				{type === 'number' && (
					<>
						<NumberValue value={example} />
						{comma && ','}
					</>
				)}
				{type === 'boolean' && (
					<>
						<NumberValue value={example} />
						{comma && ','}
					</>
				)}
				{(type === 'array' || type === 'array of objects') && '['}
				{type === 'object' && '{'}
			</div>
			{(type === 'array of objects' || type === 'object') && (
				<div
					className="code-block--body--content--item"
					style={{
						marginLeft: '20px',
						// marginTop: '10px',
						// padding: '10px',
						// borderRadius: '10px',
					}}
				>
					{type === 'array of objects' && `{`}
					{example?.map?.((subItem: any, index: number) => (
						<RequestBodyExampleItem
							key={subItem.name}
							name={subItem.name}
							type={subItem.type}
							required={subItem.required}
							description={subItem.description}
							example={subItem.example}
							comma={index < (example?.length ?? 1) - 1}
						/>
					)) ?? ''}
					{`}`}
					{type === 'object' && comma && ','}
					{/* {comma && ','} */}
				</div>
			)}
			{type === 'array' &&
				example?.map?.((subItem: any, index: number) =>
					typeof subItem === 'string' ? (
						<div
							key={`${subItem}-${index}`}
							style={{
								marginLeft: '20px',
							}}
						>
							<StringValue value={subItem} />
							{index < example.length - 1 && (
								<>
									,
									<br />
								</>
							)}
						</div>
					) : (
						<div
							key={`${String(subItem)}-${index}`}
							style={{
								marginLeft: '20px',
							}}
						>
							<NumberValue value={subItem} />
							{index < example.length - 1 && (
								<>
									,
									<br />
								</>
							)}
						</div>
					),
				)}
			{(type === 'array' || type === 'array of objects') && ']'}
			{(type === 'array' || type === 'array of objects') && comma && ','}
		</div>
	);
};
