/* eslint-disable react/no-array-index-key */
import { IconButton, Paper, Typography } from '@mui/material';
import React, { FC, useState } from 'react';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import DoneIcon from '@mui/icons-material/Done';
import { TRequestQueryExampleProps } from './types';

export const RequestQueryExample: FC<TRequestQueryExampleProps> = ({ url, queries, type }) => {
	const [copy, setCopy] = useState(false);
	const newUrl = queries.reduce(
		(accumulator, item, index) =>
			`${accumulator}${index > 0 ? '&\n' : '?\n'}${String(item.name)}=${String(item.example)}`,
		`${url}`,
	);
	const newUrlArray = newUrl.split('\n');

	const copyToClipboard = () => {
		navigator.clipboard
			.writeText(newUrl.replaceAll('\n', '') ?? '') // preRef.current?.innerText ?? '')
			.then(() => {
				setCopy(true);
				setTimeout(() => {
					setCopy(false);
				}, 2000);
			})
			.catch((err) => {
				console.log('Something went wrong', err);
			});
	};

	return (
		<Paper
			style={{
				position: 'sticky',
				top: '60px',
			}}
		>
			<Typography variant="body1" component="div" sx={{ p: 2 }}>
				<div style={{ display: 'flex', justifyContent: 'space-between' }}>
					<h3>{type}</h3>
					{copy ? (
						<DoneIcon color="success" />
					) : (
						<IconButton onClick={copyToClipboard}>
							<ContentCopyIcon />
						</IconButton>
					)}
				</div>
				<div style={{ fontSize: '1.2rem', overflow: 'scroll', whiteSpace: 'normal' }}>
					<div>
						<span style={{ color: '#000', fontWeight: 500 }}>{url}</span>
						<span style={{ color: '#9e9e9e' }}>?</span>
					</div>
					{queries.map((item, index) => (
						<div key={index}>
							<span style={{ color: '#c85b63' }}>{item.name}=</span>
							<span style={{ color: '#009939' }}>{item.example}</span>
							{index < queries.length - 1 ? <span style={{ color: '#9e9e9e' }}>&</span> : ''}
						</div>
					))}
				</div>
			</Typography>
		</Paper>
	);
};
