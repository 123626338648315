export const STATUS_PREPROCESSING = 'preprocessing';
export const STATUS_PREPROCESSED = 'preprocessed';
export const STATUS_PREPROCESSING_FAILED = 'preprocessing failed';
export const STATUS_VERIFYING_BY_OPERATOR = 'verifying by operator';
export const STATUS_IMPORT_FAILED = 'import failed';
export const STATUS_PARTIALLY_PROCESSED = 'partially processed';
export const STATUS_PROCESSED = 'processed';

export const RESPONSE_200 = '200';
export const RESPONSE_201 = '201';
export const RESPONSE_204 = '204';
export const RESPONSE_400 = '400';
export const RESPONSE_401 = '401';
export const RESPONSE_403 = '403';
export const RESPONSE_404 = '404';
export const RESPONSE_405 = '405';
export const RESPONSE_409 = '409';
export const RESPONSE_500 = '500';

export const responseType = {
	[RESPONSE_200]: RESPONSE_200,
	[RESPONSE_201]: RESPONSE_201,
	[RESPONSE_204]: RESPONSE_204,
	[RESPONSE_400]: RESPONSE_400,
	[RESPONSE_401]: RESPONSE_401,
	[RESPONSE_403]: RESPONSE_403,
	[RESPONSE_404]: RESPONSE_404,
	[RESPONSE_405]: RESPONSE_405,
	[RESPONSE_409]: RESPONSE_409,
	[RESPONSE_500]: RESPONSE_500,
};

export const GET = 'GET';
export const POST = 'POST';
export const PUT = 'PUT';
export const DELETE = 'DELETE';
export const PATCH = 'PATCH';

export const apiMethod = {
	GET,
	POST,
	PUT,
	DELETE,
	PATCH,
};
