/* eslint-disable react/prop-types */
import { Button, Paper, Typography } from '@mui/material';
import { FC, useState } from 'react';
import { RequestBodyItem } from './RequestBodyItem';
import { TBodyDescriptionProps } from './types';

export const BodyDescription: FC<TBodyDescriptionProps> = ({ data, type, bodyType }) => {
	const [expandedAll, setExpandedAll] = useState<boolean>(false);
	return (
		<Paper
			style={{
				position: 'sticky',
				top: '60px',
			}}
		>
			<Typography component="div" sx={{ p: 2 }}>
				<div style={{ display: 'flex', justifyContent: 'space-between' }}>
					<div style={{ fontSize: '1.4rem', fontWeight: 'bold' }}>
						{type}{' '}
						<i style={{ color: '#666666', fontSize: '1.1rem', fontWeight: 'normal' }}>{bodyType}</i>
					</div>
					<Button size="small" onClick={() => setExpandedAll(!expandedAll)}>
						{expandedAll ? 'Collapse All' : 'Expand All'}
					</Button>
				</div>
				<hr style={{ border: '0.5px solid #cccccc' }} />

				<div>
					{data?.map?.((item: any) => (
						<RequestBodyItem
							key={item.name}
							name={item.name}
							type={item.type}
							description={item.description}
							example={item.example}
							required={item.required}
							expandedAll={expandedAll}
						/>
					)) ?? ''}
				</div>
			</Typography>
		</Paper>
	);
};
