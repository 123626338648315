/* eslint-disable @typescript-eslint/unbound-method */
/* eslint-disable @typescript-eslint/no-explicit-any */
import axios from 'axios';
import { call, put, takeEvery, take, select } from 'redux-saga/effects';
import { api } from 'services/api';
import { PayloadAction } from '@reduxjs/toolkit';
import { notificationContainer } from 'utils/notificationContainer';
import fileDownload from 'js-file-download';
import {
	newVATReportRequest,
	newVATReportSuccess,
	newVATReportWithPaymentRequest,
	settingsInitState,
	vatReportFileRequest,
	vatReportFileSuccess,
	vatReportStatusRequest,
	vatReportStatusSuccess,
} from './reducer';
import {
	TNewVATReportFileTypes,
	TNewVATReportResponsePayload,
	TVatReportFileRequestPayload,
	TVatReportFileResponsePayload,
	TVatReportStatusRequestPayload,
	TVatReportStatusResponsePayload,
} from './types';
import { newVatReportFileType } from './selectors';
import { NEW_FILE_TYPE_WITH_PAYMENT } from './constants';

const date = new Date();

function* newVATReportWorker() {
	try {
		const response: TNewVATReportResponsePayload = yield call(api.vatreport.newVATReport);
		yield put(newVATReportSuccess(response));
	} catch (error) {
		if (axios.isAxiosError(error)) {
			notificationContainer(String(error), 'error');
		}
		yield put(settingsInitState());
	}
}

function* newVATReportWithPaymentWorker() {
	try {
		const response: TNewVATReportResponsePayload = yield call(
			api.vatreport.newVATReportWithPayment,
		);
		yield put(newVATReportSuccess(response));
	} catch (error) {
		if (axios.isAxiosError(error)) {
			notificationContainer(String(error), 'error');
		}
		yield put(settingsInitState());
	}
}

function* vatReportStatusWorker(action: PayloadAction<TVatReportStatusRequestPayload>) {
	const { payload } = action;
	try {
		const response: TVatReportStatusResponsePayload = yield call(
			api.vatreport.vatReportStatus,
			payload,
		);
		yield put(vatReportStatusSuccess(response));
	} catch (error) {
		if (axios.isAxiosError(error)) {
			notificationContainer(String(error), 'error');
		}
		// yield put(settingsInitState());
	}
}

function* vatReportFileWorker(action: PayloadAction<TVatReportFileRequestPayload>) {
	const { payload } = action;
	// const typeofFile = take(vatReportFileRequest);
	try {
		const state: TNewVATReportFileTypes = yield select(newVatReportFileType);
		const typeOfFile = state === NEW_FILE_TYPE_WITH_PAYMENT ? '_with-payment' : '';

		const response: TVatReportFileResponsePayload = yield call(
			api.vatreport.vatReportFile,
			payload,
		);
		yield put(vatReportFileSuccess(response));
		console.log('response 0', response);

		if (response) {
			console.log('response', response);
			yield fileDownload(
				response,
				`VAT-report${typeOfFile}_${String(date.getFullYear())}-${String(
					date.getMonth() + 1,
				)}-${String(date.getDate())}_${String(date.getHours())}-${String(date.getMinutes())}.xml`,
			);
		}
		yield put(settingsInitState());
	} catch (error) {
		if (axios.isAxiosError(error)) {
			notificationContainer(String(error), 'error');
		}
		yield put(settingsInitState());
	}
}

export function* vatReportSaga() {
	yield takeEvery(newVATReportRequest.type, newVATReportWorker);
	yield takeEvery(newVATReportWithPaymentRequest.type, newVATReportWithPaymentWorker);
	yield takeEvery(vatReportStatusRequest.type, vatReportStatusWorker);
	yield takeEvery(vatReportFileRequest.type, vatReportFileWorker);
}
