import { Page } from 'layouts/Page';
import { FC } from 'react';
import { PermanentDrawer } from 'components/PermanentDrawer';

export const ApiDocPage: FC = () => {
	return (
		<Page title="ApiDocPage">
			<PermanentDrawer />
		</Page>
	);
};
