/* eslint-disable no-useless-escape */
export const escapeMarkdown = (str: string) => {
	let isInsideNameLink = false;
	let isInsideLink = false;

	let result = '';

	for (let i = 0; i < str.length; i += 1) {
		const char = str[i];

		if (char === '[') {
			isInsideNameLink = true;
		} else if (char === ']') {
			isInsideNameLink = false;
			if (str[i + 1] === '(') {
				isInsideLink = true;
			}
		} else if (isInsideLink && str[i - 1] === ')') {
			isInsideLink = false;
		}

		if (!isInsideLink && /[+.,!=#\-()`>{}!]/.test(char)) {
			result += '\\';
		}

		result += char;
	}

	return result;
};

export const unEscapeMarkdown = (str: string) => str?.replace(/\\([+.,!=#\-()`>{}!])/g, '$1');
