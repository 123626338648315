import { ApiDocPage } from 'pages/ApiDocPage';
import { useRoutes } from 'react-router';

export const NavigateList = () => {
	const routes = useRoutes([
		{
			path: `/:apiPage`,
			element: <ApiDocPage />,
		},
		{
			path: `/:apiPage/:apiSubPage`,
			element: <ApiDocPage />,
		},
		{
			path: `/`,
			element: <ApiDocPage />,
		},
	]);
	return routes;
};
