/* eslint-disable no-nested-ternary */
/* eslint-disable react/no-array-index-key */
/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable @typescript-eslint/restrict-template-expressions */
import { Chip, Stack } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import { FC, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import apiList from 'data/apiList.json';
import { MarkdownToHtmlConverter } from 'ui/MarkdownToHtmlConverter';
import authKeys from 'data/auth/auth-keys.json';
import openIdConnect from 'data/auth/open-id-connect.json';

import calculate from 'data/calculation/calculate.json';
import createpostsaleorder from 'data/calculation/createpostsaleorder.json';
import updateExternalOrderId from 'data/confirmation/updateExternalOrderId.json';
import confirmpostsaleorder from 'data/confirmation/confirmpostsaleorder.json';

import get_post_sale_without_lc_job_status from 'data/mass-sale/get_post_sale_without_lc_job_status.json';
import get_post_sale_without_lc_order_status from 'data/mass-sale/get_post_sale_without_lc_order_status.json';
import create_post_sale_without_lc_orders from 'data/mass-sale/create_post_sale_without_lc_orders.json';
import create_mass_post_sale_orders from 'data/mass-sale/create_mass_post_sale_orders.json';
import get_mass_post_sale_job_status from 'data/mass-sale/get_mass_post_sale_job_status.json';
import get_mass_post_sale_order_status from 'data/mass-sale/get_mass_post_sale_order_status.json';

import verify from 'data/payment/verify.json';
import reject from 'data/payment/reject.json';

import self from 'data/user/self.json';

import create_shipment from 'data/shipment/create_shipment.json';

import create_return_with_lc from 'data/returns/create_return_with_lc.json';
import create_return_without_lc from 'data/returns/create_return_without_lc.json';
import confirm_refund from 'data/returns/confirm_refund.json';

import em_order_list from 'data/order-management/em_order_list.json';
import em_returns_list from 'data/order-management/em_returns_list.json';
import em_order_breakdown from 'data/order-management/em_order_breakdown.json';
import em_return_breakdown from 'data/order-management/em_return_breakdown.json';
import fetch_supported_country_list_for_em from 'data/order-management/fetch_supported_country_list_for_em.json';

import create_discount from 'data/discount/create_discount.json';

import { BodyDescription } from './BodyDescription';
import { RequestBodyExample } from './RequestBodyExample';
import { ApiAlert } from './ApiAlert';
import { TMethod, TResponseType } from './types';
import { RESPONSE_200 } from './constants';
import { RequestPathExample } from './RequestPathExample';
import { RequestQueryExample } from './RequestQueryExample';
import { RequestStringBodyExample } from './RequestStringBodyExample';

export const PermanentDrawer: FC = () => {
	const navigate = useNavigate();
	const { apiPage, apiSubPage } = useParams<{ apiPage: string; apiSubPage: string }>();
	const apiPageObj = apiList.find((item) => item.url === apiPage);
	const apiSubPageObj = apiPageObj?.pages?.find((item) => item.url === apiSubPage);

	const [typeResponse, setTypeResponse] = useState<TResponseType>(RESPONSE_200);

	const handleTypeResponse = (type: TResponseType) => {
		setTypeResponse(type);
	};

	const data: any =
		apiSubPage === 'get-access-token'
			? authKeys
			: apiSubPage === 'open-id-connect'
			? openIdConnect
			: apiSubPage === 'post-calculate'
			? calculate
			: apiSubPage === 'createpostsaleorder'
			? createpostsaleorder
			: apiSubPage === 'updateExternalOrderId'
			? updateExternalOrderId
			: apiSubPage === 'confirmpostsaleorder'
			? confirmpostsaleorder
			: apiSubPage === 'create_post_sale_without_lc_orders'
			? create_post_sale_without_lc_orders
			: apiSubPage === 'get_post_sale_without_lc_job_status'
			? get_post_sale_without_lc_job_status
			: apiSubPage === 'em_order_list'
			? em_order_list
			: apiSubPage === 'em_returns_list'
			? em_returns_list
			: apiSubPage === 'em_order_breakdown'
			? em_order_breakdown
			: apiSubPage === 'em_return_breakdown'
			? em_return_breakdown
			: apiSubPage === 'fetch_supported_country_list_for_em'
			? fetch_supported_country_list_for_em
			: apiSubPage === 'get_post_sale_without_lc_order_status'
			? get_post_sale_without_lc_order_status
			: apiSubPage === 'create_mass_post_sale_orders'
			? create_mass_post_sale_orders
			: apiSubPage === 'get_mass_post_sale_job_status'
			? get_mass_post_sale_job_status
			: apiSubPage === 'get_mass_post_sale_order_status'
			? get_mass_post_sale_order_status
			: apiSubPage === 'verify'
			? verify
			: apiSubPage === 'reject'
			? reject
			: apiSubPage === 'self'
			? self
			: apiSubPage === 'create_shipment'
			? create_shipment
			: apiSubPage === 'create_return_with_lc'
			? create_return_with_lc
			: apiSubPage === 'create_return_without_lc'
			? create_return_without_lc
			: apiSubPage === 'confirm_refund'
			? confirm_refund
			: apiSubPage === 'create_discount'
			? create_discount
			: [];

	// if (!apiPage) {
	// 	navigate('/introduction');
	// }

	useEffect(() => {
		if (!apiPage) {
			navigate('/introduction/common-information');
		}
	}, [apiPage, navigate]);

	// if (!apiPageObj) {
	// 	return (
	// 		<div className="api-body">
	// 			<Alert severity="error">Page not found</Alert>
	// 		</div>
	// 	);
	// }

	return (
		<div className="api-body" style={{ position: 'relative', width: '100%' }}>
			<h1 style={{ color: '#016193' }}>{apiSubPageObj?.name ?? apiPageObj?.name}</h1>
			{apiSubPageObj?.method && apiSubPageObj?.endpoint && (
				<ApiAlert
					method={apiSubPageObj?.method as TMethod}
					endpoint={apiSubPageObj?.endpoint ?? ''}
				/>
			)}
			{(apiSubPageObj?.description || apiPageObj?.description) && (
				<Grid container spacing={2}>
					<Grid xs={12} sm={12} md={12} lg={8} xl={6}>
						<div style={{ fontSize: '1.2rem', marginTop: '2rem' }}>
							{MarkdownToHtmlConverter({
								markdownText: apiSubPageObj?.description ?? apiPageObj?.description ?? '',
							})}
						</div>
					</Grid>
				</Grid>
			)}
			{apiSubPageObj?.method && apiSubPageObj?.endpoint && (
				<Grid container spacing={2}>
					<>
						<Grid xs={12} sm={12} md={12} lg={12}>
							<h2>Request</h2>
							<hr />
						</Grid>
						<Grid xs={12} sm={6} md={6} lg={6}>
							<h3 style={{ color: '#666666' }}>
								{(data?.header?.length ?? 0) > 0 ||
								(data?.body?.length ?? 0) > 0 ||
								(data?.query?.length ?? 0) > 0 ||
								(data?.path?.length ?? 0) > 0 ||
								(data?.file?.length ?? 0) > 0
									? 'Data Description'
									: 'No Parameters'}
							</h3>
						</Grid>

						<Grid xs={12} sm={6} md={6} lg={6}>
							<h3 style={{ color: '#666666' }}>
								{(data?.header?.length ?? 0) > 0 ||
								(data?.body?.length ?? 0) > 0 ||
								(data?.query?.length ?? 0) > 0 ||
								(data?.path?.length ?? 0) > 0 ||
								(data?.file?.length ?? 0) > 0
									? 'Examples'
									: ''}
							</h3>
						</Grid>

						{(data?.path?.length ?? 0) > 0 && (
							<>
								<Grid xs={12} sm={6} md={6} lg={6}>
									<BodyDescription data={data.path} type="Path" bodyType="" />
								</Grid>
								<Grid xs={12} sm={6} md={6} lg={6}>
									<RequestPathExample url={data.url} paths={data?.path} />
								</Grid>
							</>
						)}

						{(data?.query?.length ?? 0) > 0 && (
							<>
								<Grid xs={12} sm={6} md={6} lg={6}>
									<BodyDescription data={data.query} type="Query" bodyType="" />
								</Grid>
								<Grid xs={12} sm={6} md={6} lg={6}>
									<RequestQueryExample url={data.url} queries={data?.query} type="Query" />
								</Grid>
							</>
						)}

						{(data?.header?.length ?? 0) > 0 && (
							<>
								<Grid xs={12} sm={6} md={6} lg={6}>
									<BodyDescription data={data.header} type="Header" bodyType="" />
								</Grid>
								<Grid xs={12} sm={6} md={6} lg={6}>
									<RequestBodyExample data={data.header} parameterType="Header" bodyType="" />
								</Grid>
							</>
						)}

						{(data?.body?.length ?? 0) > 0 && (
							<>
								<Grid xs={12} sm={6} md={6} lg={6}>
									<BodyDescription
										data={data.body}
										type="Body"
										bodyType={data?.bodyType ?? 'object'}
									/>
								</Grid>
								<Grid xs={12} sm={6} md={6} lg={6}>
									<RequestBodyExample
										data={data.body}
										parameterType="Body"
										bodyType={data?.bodyType ?? 'object'}
									/>
								</Grid>
							</>
						)}
						{(data?.file?.length ?? 0) > 0 && (
							<>
								<Grid xs={12} sm={6} md={6} lg={6}>
									<BodyDescription data={data.file} type="File" bodyType={data?.fileType ?? ''} />
								</Grid>
								<Grid xs={12} sm={6} md={6} lg={6}>
									<RequestBodyExample
										data={data.file}
										parameterType="File"
										bodyType={data?.bodyType ?? 'object'}
									/>
								</Grid>
							</>
						)}

						<Grid xs={12} sm={12} md={12} lg={12}>
							<h2>Response</h2>
							<hr />
							<Stack direction="row" spacing={1}>
								<Chip
									label="200"
									variant={typeResponse === '200' ? 'filled' : 'outlined'}
									color="primary"
									onClick={() => handleTypeResponse('200')}
								/>
								<Chip
									label="400"
									variant={typeResponse === '400' ? 'filled' : 'outlined'}
									color="error"
									onClick={() => handleTypeResponse('400')}
								/>
								<Chip
									label="401"
									variant={typeResponse === '401' ? 'filled' : 'outlined'}
									color="error"
									onClick={() => handleTypeResponse('401')}
								/>
								<Chip
									label="403"
									variant={typeResponse === '403' ? 'filled' : 'outlined'}
									color="error"
									onClick={() => handleTypeResponse('403')}
								/>
								<Chip
									label="404"
									variant={typeResponse === '404' ? 'filled' : 'outlined'}
									color="error"
									onClick={() => handleTypeResponse('404')}
								/>
								<Chip
									label="500"
									variant={typeResponse === '500' ? 'filled' : 'outlined'}
									color="error"
									onClick={() => handleTypeResponse('500')}
								/>
							</Stack>
						</Grid>
						<Grid xs={12} sm={6} md={6} lg={6}>
							{data.response && <h3>{data.response?.[typeResponse]?.description ?? ''}</h3>}
						</Grid>

						<Grid xs={12} sm={6} md={6} lg={6} />

						{data?.response &&
						typeResponse in data?.response &&
						((data?.response?.[typeResponse]?.body?.length ?? 0) > 0 ||
							(data?.response?.[typeResponse]?.redirect?.length ?? 0) > 0) ? (
							<>
								<Grid xs={12} sm={6} md={6} lg={6}>
									<h3 style={{ color: '#666666' }}>Data Description</h3>
								</Grid>
								<Grid xs={12} sm={6} md={6} lg={6}>
									<h3 style={{ color: '#666666' }}>Examples</h3>
								</Grid>
							</>
						) : (
							<Grid xs={12}>
								<h3 style={{ color: '#666666' }}>No Parameters</h3>
							</Grid>
						)}
						{(data?.response?.[typeResponse]?.body?.length ?? 0) > 0 &&
							((data?.response?.[typeResponse]?.bodyType ?? 'object') === 'object' ||
								(data?.response?.[typeResponse]?.bodyType ?? 'object') === 'array of objects') && (
								<>
									<Grid xs={12} sm={6} md={6} lg={6}>
										<BodyDescription
											data={data?.response?.[typeResponse]?.body ?? {}}
											type="Body"
											bodyType={data?.response?.[typeResponse]?.bodyType ?? 'object'}
										/>
									</Grid>
									<Grid xs={12} sm={6} md={6} lg={6}>
										<RequestBodyExample
											data={data?.response?.[typeResponse]?.body}
											parameterType="Body"
											bodyType={data?.response?.[typeResponse]?.bodyType ?? 'object'}
										/>
									</Grid>
								</>
							)}
						{(data?.response?.[typeResponse]?.body?.length ?? 0) > 0 &&
							(data?.response?.[typeResponse]?.bodyType ?? 'object') === 'string' && (
								<>
									<Grid xs={12} sm={6} md={6} lg={6}>
										<BodyDescription
											data={data?.response?.[typeResponse]?.body ?? {}}
											type="Body"
											bodyType="string"
										/>
									</Grid>
									<Grid xs={12} sm={6} md={6} lg={6}>
										<RequestStringBodyExample
											data={data?.response?.[typeResponse]?.bodyExample ?? ''}
										/>
									</Grid>
								</>
							)}

						{(data?.response?.[typeResponse]?.redirect?.length ?? 0) > 0 && (
							<>
								<Grid xs={12} sm={6} md={6} lg={6}>
									<BodyDescription
										data={data?.response?.[typeResponse]?.redirect ?? {}}
										type="Redirect URL"
										bodyType=""
									/>
								</Grid>
								<Grid xs={12} sm={6} md={6} lg={6}>
									<RequestQueryExample
										url="/"
										queries={data?.response?.[typeResponse]?.redirect}
										type="Redirect URL"
									/>
								</Grid>
							</>
						)}
						{(data?.response?.[typeResponse]?.jwt?.length ?? 0) > 0 && (
							<>
								<Grid xs={12} sm={6} md={6} lg={6}>
									<BodyDescription
										data={data?.response?.[typeResponse]?.jwt ?? {}}
										type="JWT Token"
										bodyType={data?.response?.[typeResponse]?.bodyType ?? 'object'}
									/>
								</Grid>
								<Grid xs={12} sm={6} md={6} lg={6}>
									<RequestBodyExample
										data={data?.response?.[typeResponse]?.jwt}
										parameterType="JWT Token"
										bodyType={data?.response?.[typeResponse]?.bodyType ?? 'object'}
									/>
								</Grid>
							</>
						)}
					</>
				</Grid>
			)}
		</div>
	);
};
