import { FC, useEffect, useState } from 'react';
import { useParams } from 'react-router';
import apiList from 'data/apiList.json';
import { ApiAlert } from '../ApiAlert';
import { TMethod } from '../types';

export const ApiHeader: FC = () => {
	const { apiPage, apiSubPage } = useParams<{ apiPage: string; apiSubPage: string }>();
	const apiPageObj = apiList.find((item) => item.url === apiPage);
	const apiSubPageObj = apiPageObj?.pages?.find((item) => item.url === apiSubPage);

	const [offset, setOffset] = useState(0);

	useEffect(() => {
		const onScroll = () => setOffset(window.scrollY);
		window.removeEventListener('scroll', onScroll);
		window.addEventListener('scroll', onScroll, { passive: true });
		return () => window.removeEventListener('scroll', onScroll);
	}, []);

	return (
		<div
			style={{
				position: 'absolute',
				justifyContent: 'space-between',
				width: 'calc(100% - 130px)',
				top: 0,
				background: 'rgb(1, 97, 147)',
				borderRadius: '25px 0 0 25px',
				height: '100%',
				display: 'flex',
				alignItems: 'center',
				marginLeft: offset >= 50 ? '130px' : '4000px',
				transition: 'margin-left .3s ease-in-out',
				cursor: 'pointer',
			}}
			onClick={() => window.scrollTo(0, 0)}
		>
			<div
				style={{
					fontSize: '1.6rem',
					fontWeight: 'bold',
					color: '#fff',
					margin: '0 1rem 0 1.2rem',
					whiteSpace: 'nowrap',
					textAlign: 'center',
					background: 'rgb(1, 97, 147)',
				}}
				className="api-header--title"
			>
				{apiSubPageObj?.name ?? apiPageObj?.name}
			</div>
			{apiSubPageObj?.endpoint && (
				<div
					style={{
						// position: 'fixed',
						width: '100%',
						// maxWidth: '60vw',
						marginRight: '10px',
						// display: offset >= 80 ? '' : 'none',
						// right: '-6000px',
						marginLeft: offset >= 100 ? '0' : '4000px',
						transition: 'margin-left .5s ease-in-out',
					}}
					className="api-header--url"
				>
					<ApiAlert
						method={apiSubPageObj?.method as TMethod}
						endpoint={apiSubPageObj?.endpoint ?? ''}
					/>
				</div>
			)}
		</div>
	);
};
