import { FC } from 'react';
import { TNumberValueProps } from './types';

export const NumberValue: FC<TNumberValueProps> = ({ value }) => {
	return (
		<span
			style={{
				color: '#e88900',
				borderRadius: '10px',
			}}
		>
			{String(value)}
		</span>
	);
};
