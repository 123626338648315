import { IconButton, Paper, Typography } from '@mui/material';
import { FC, useRef, useState } from 'react';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import DoneIcon from '@mui/icons-material/Done';
import { TRequestStringBodyExampleProps } from './types';

export const RequestStringBodyExample: FC<TRequestStringBodyExampleProps> = ({ data }) => {
	const preRef = useRef<HTMLDivElement>(null);
	const [copy, setCopy] = useState(false);
	const copyToClipboard = () => {
		navigator.clipboard
			.writeText(preRef.current?.innerText ?? '')
			.then(() => {
				console.log('copied');
				setCopy(true);
				setTimeout(() => {
					setCopy(false);
				}, 2000);
			})
			.catch((err) => {
				console.log('Something went wrong', err);
			});
	};

	const varRegex = /\{([^}]+)\}/g;
	// const newUrlHTML = data.replace(varRegex, '<span style="color: #009939">$1</span>');
	const parts = data.split(varRegex);
	const matches = data.match(varRegex);

	return (
		<Paper
			style={{
				position: 'sticky',
				top: '60px',
			}}
		>
			<Typography variant="body1" component="div" sx={{ p: 2 }}>
				<div style={{ display: 'flex', justifyContent: 'space-between' }}>
					<h3>Body</h3>
					{copy ? (
						<DoneIcon color="success" />
					) : (
						<IconButton onClick={copyToClipboard}>
							<ContentCopyIcon />
						</IconButton>
					)}
				</div>
				<div
					style={{
						fontSize: '1.2rem',
						overflow: 'scroll',
						whiteSpace: 'normal',
						color: '#009939',
						fontWeight: 500,
					}}
					ref={preRef}
				>
					&#96;
					{parts.map((part, index) => {
						if (index % 2 === 0) {
							return part;
						}
						return (
							// eslint-disable-next-line react/no-array-index-key
							<span key={index} style={{ color: '#e88900' }}>
								$<span>&#123;</span>
								<span>{part}</span>
								<span>&#125;</span>
							</span>
						);
					})}
					&#96;
				</div>
			</Typography>
		</Paper>
	);
};
