import { FC } from 'react';
import { TStringValueProps } from './types';

export const StringValue: FC<TStringValueProps> = ({ value }) => {
	return (
		<span
			style={{
				color: '#009939',
				borderRadius: '10px',
			}}
		>
			&quot;{value}&quot;
		</span>
	);
};
