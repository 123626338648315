import { FC } from 'react';

export const Footer: FC = () => {
	return (
		<footer id="footer">
			<section className="footer-container">
				<div>
					<img className="logo-footer" src="/img/EAS-logo.svg" alt="" />
				</div>
				<div className="footer-menu-col">
					<div className="footer-menu-item">
						<b>Solutions</b>
					</div>
					<div className="footer-menu-item">
						<a href="https://easproject.com/">eCommerce</a>
					</div>
					<div className="footer-menu-item">
						<a href="https://easproject.com/blockchain/">CRCD Blockchain</a>
					</div>
					<div className="footer-menu-item">
						<a href="https://easproject.com/privacy-policy">Privacy policy</a>
					</div>
					<div className="footer-menu-item">
						<a href="https://easproject.com/cookie-policy/">Cookie Policy</a>
					</div>
				</div>
				<div className="footer-menu-col">
					<div className="footer-menu-item">
						<b>About us</b>
					</div>
					<div className="footer-menu-item">
						<a href="https://easproject.com/about-us/">EAS Project</a>
					</div>
					<div className="footer-menu-item">
						<a href="https://easproject.com/about-us/">Team</a>
					</div>
					<div className="footer-menu-item">
						<a href="https://easproject.com/about-us/">Contact Us</a>
					</div>
				</div>
				<div className="footer-menu-col">
					<div className="footer-menu-item">
						<b>EAS Project</b>
					</div>
					{/* <div className="footer-menu-item"> Hiilikatu 3 </div> */}
					{/* <div className="footer-menu-item"> 00180, Helsinki </div> */}
					{/* <div className="footer-menu-item"> Finland </div> */}
					<div className="footer-menu-item">
						<a style={{ color: '#016193' }} href="mailto:info@easproject.com">
							info@easproject.com
						</a>
					</div>
					<div className="footer-menu-item"> VAT Number: EE102417575 </div>
				</div>
			</section>
			<section className="footer-copirigt"> © All rights reserved </section>
		</footer>
	);
};
