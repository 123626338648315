import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Avatar from '@mui/material/Avatar';
import { ApiHeader } from 'components/PermanentDrawer/ApiHeader';

const ResponsiveAppBar = () => {
	return (
		<AppBar
			position="static"
			sx={{
				marginBottom: 2,
				backgroundColor: 'white',
				color: '#016193',
				position: 'sticky',
				top: '0px',
				zIndex: 1000,
				height: '3.6rem!important',
			}}
		>
			<div style={{ margin: '0 1.8rem' }}>
				<Toolbar
					disableGutters
					sx={{ minHeight: '3.6rem!important', display: 'flex', alignItems: 'center' }}
				>
					{/* <AdbIcon sx={{ display: { xs: 'none', md: 'flex' }, mr: 1 }} /> */}
					<Avatar
						alt="EAS API DOC"
						src="/img/EAS-logo-new.svg"
						sx={{ display: { xs: 'none', md: 'flex' }, mr: 1, height: '3.2rem', width: 'auto' }}
					/>
					<Typography
						variant="h6"
						noWrap
						component="a"
						href="/"
						sx={{
							mr: 2,
							display: { xs: 'none', md: 'flex' },
							fontFamily: 'monospace',
							fontWeight: 700,
							letterSpacing: '.3rem',
							color: 'inherit',
							textDecoration: 'none',
							fontSize: '1.5rem',
						}}
					>
						API
					</Typography>
					<Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }} />
					<Avatar
						alt="EAS API DOC"
						src="/img/new-logo.webp"
						sx={{ display: { xs: 'flex', md: 'none' }, mr: 1 }}
					/>

					<Typography
						variant="h5"
						noWrap
						component="a"
						href="/"
						sx={{
							mr: 2,
							display: { xs: 'flex', md: 'none' },
							flexGrow: 1,
							fontFamily: 'monospace',
							fontWeight: 700,
							letterSpacing: '.3rem',
							color: 'inherit',
							textDecoration: 'none',
							fontSize: '1.6rem',
						}}
					>
						API
					</Typography>
				</Toolbar>
			</div>
			<ApiHeader />
		</AppBar>
	);
};
export default ResponsiveAppBar;
