import Header from 'layouts/Header';
import { FC } from 'react';
import { useSelector } from 'react-redux';
// import Footer from 'layouts/Footer';
// import { Header } from 'layouts/Header';
// import { getTheme } from 'redux/reducers/settings/selectors';
// import { SettingsModal } from 'layouts/Modals/SettingsModal';
import { Grid } from '@mui/material';
import { VerticalNavBar } from 'layouts/VerticalNavBar';
import { Footer } from 'layouts/Footer';
import { TPageProps } from './types';

// ==========================================:
export const Page: FC<TPageProps> = ({ children, title }) => {
	const pageTitle = title ? `| ${title}` : '';

	// const theme = useSelector(getTheme);

	return (
		<div style={{ backgroundColor: '#f5f5f5' }} className="main-container">
			<Header />
			<main className="main" style={{ padding: '10px' }}>
				{/* <div className="main-content">{children}</div> */}
				<Grid container spacing={2} sx={{ marginLeft: '0px', width: '100%' }}>
					<Grid xs={12} sm={12} md={12} lg={3} xl={2.5}>
						<VerticalNavBar />
					</Grid>
					<Grid xs={12} sm={12} md={12} lg={9} xl={9.5}>
						{children}
					</Grid>
				</Grid>
			</main>
			<Footer />
		</div>
	);
};
