import { IconButton, Paper, Typography } from '@mui/material';
import { FC, useState } from 'react';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import DoneIcon from '@mui/icons-material/Done';
import { TRequestPathExampleProps } from './types';

export const RequestPathExample: FC<TRequestPathExampleProps> = ({ url, paths }) => {
	const [copy, setCopy] = useState(false);
	const newUrl = paths.reduce(
		(accumulator, item) =>
			accumulator?.replace?.(`{${String(item.name)}}`, `${String(item.example)}`),
		url,
	);
	const copyToClipboard = () => {
		navigator.clipboard
			.writeText(newUrl ?? '')
			.then(() => {
				setCopy(true);
				setTimeout(() => {
					setCopy(false);
				}, 2000);
			})
			.catch((err) => {
				console.log('Something went wrong', err);
			});
	};

	const newUrlHTML = paths.reduce(
		(accumulator, item) =>
			accumulator?.replace?.(
				`{${String(item.name)}}`,
				`<span style="color: #009939">${String(item.example)}</span>`,
			),
		url,
	);
	const createMarkup = () => {
		return { __html: newUrlHTML };
	};

	return (
		<Paper
			style={{
				position: 'sticky',
				top: '60px',
			}}
		>
			<Typography variant="body1" component="div" sx={{ p: 2 }}>
				<div style={{ display: 'flex', justifyContent: 'space-between' }}>
					<h3>Path</h3>
					{copy ? (
						<DoneIcon color="success" />
					) : (
						<IconButton onClick={copyToClipboard}>
							<ContentCopyIcon />
						</IconButton>
					)}
				</div>
				{/* <div style={{ fontSize: '1.2rem' }}>{newUrlHTML}</div> */}
				<div style={{ fontSize: '1.2rem' }} dangerouslySetInnerHTML={createMarkup()} />
			</Typography>
		</Paper>
	);
};
