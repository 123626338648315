import { IconButton, Paper, Typography } from '@mui/material';
import { FC, useRef, useState } from 'react';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import DoneIcon from '@mui/icons-material/Done';
import { RequestBodyExampleItem } from './RequestBodyExampleItem';
import { TRequestBodyExampleProps } from './types';

export const RequestBodyExample: FC<TRequestBodyExampleProps> = ({
	data,
	parameterType,
	bodyType,
}) => {
	const preRef = useRef<HTMLDivElement>(null);
	const [copy, setCopy] = useState(false);
	const copyToClipboard = () => {
		navigator.clipboard
			.writeText(preRef.current?.innerText ?? '')
			.then(() => {
				console.log('copied');
				setCopy(true);
				setTimeout(() => {
					setCopy(false);
				}, 2000);
			})
			.catch((err) => {
				console.log('Something went wrong', err);
			});
	};

	return (
		<Paper
			style={{
				position: 'sticky',
				top: '60px',
			}}
		>
			<Typography variant="body1" component="div" sx={{ p: 2 }}>
				<div style={{ display: 'flex', justifyContent: 'space-between' }}>
					<h3>{parameterType}</h3>
					{copy ? (
						<DoneIcon color="success" />
					) : (
						<IconButton onClick={copyToClipboard}>
							<ContentCopyIcon />
						</IconButton>
					)}
				</div>
				<div ref={preRef}>
					{bodyType === 'array of objects' ? (
						<div>
							<span style={{ fontSize: '1rem' }}>[</span>
							<div style={{ marginLeft: '20px' }}>
								<div>{`{`}</div>
								{data?.map?.((item: any, index: number) => (
									<RequestBodyExampleItem
										key={item.name}
										name={item.name}
										type={item.type}
										description={item.description}
										example={item.example}
										required={item.required}
										comma={index < (data?.length ?? 1) - 1}
									/>
								)) ?? ''}

								<div>{`}`}</div>
							</div>
							<span style={{ fontSize: '1rem' }}>]</span>
						</div>
					) : (
						<>
							{parameterType !== 'Header' && <span style={{ fontSize: '1rem' }}>{`{`}</span>}
							{data?.map?.((item: any, index: number) => (
								<RequestBodyExampleItem
									key={item.name}
									name={item.name}
									type={item.type}
									description={item.description}
									example={item.example}
									required={item.required}
									comma={index < (data?.length ?? 1) - 1}
								/>
							)) ?? ''}
							{parameterType !== 'Header' && <span style={{ fontSize: '1rem' }}>{`}`}</span>}
						</>
					)}
				</div>
			</Typography>
		</Paper>
	);
};
