import { FC, SyntheticEvent, useEffect, useState } from 'react';
import { Accordion, AccordionDetails, AccordionSummary } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useNavigate, useParams } from 'react-router';
import apiList from 'data/apiList.json';

export const VerticalNavBar: FC = () => {
	const navigate = useNavigate();
	const { apiPage, apiSubPage } = useParams<{ apiPage: string; apiSubPage: string }>();

	const [expanded, setExpanded] = useState<(boolean | undefined)[]>(
		apiList.map((item) => item.url === apiPage),
	);

	const handleChange =
		(index: number) => (event: SyntheticEvent, isExpanded: boolean | undefined) => {
			const newExpanded = [...expanded];
			newExpanded[index] = isExpanded;
			setExpanded(newExpanded);
		};

	const handleNavigate = (path: string) => {
		window.scrollTo(0, 0);
		navigate(path);
	};

	useEffect(() => {
		setExpanded(apiList.map((item) => item.url === apiPage));
	}, [apiPage, navigate]);

	return (
		<nav
			style={{
				marginTop: '20px',
				paddingRight: '20px',
				maxHeight: 'calc(100vh - 105px)',
				overflowY: 'auto',
				position: 'sticky',
				top: 'calc(105px)',
			}}
		>
			{apiList.map((item, index) => {
				if (item.type === 'page')
					return (
						<button
							key={item.url}
							type="button"
							className="vertical-nav-item vertical-nav-item-without-accordion"
							onClick={() => handleNavigate(`/${String(item?.url ?? '')}`)}
						>
							{item.name}
						</button>
					);
				return (
					<Accordion
						key={item.url}
						expanded={expanded[index]}
						onChange={handleChange(index)}
						sx={{
							width: '100%',
							border: 'none',
							boxShadow: 'none',
						}}
					>
						<AccordionSummary
							expandIcon={<ExpandMoreIcon />}
							aria-controls="panel1a-content"
							id="panel1a-header"
							sx={{
								border: 'none',
								backgroundColor: item.url === apiPage ? '#015f9312' : '',
								borderRadius: '5px',
							}}
							style={{
								backgroundColor: item.url === apiPage ? '#015f9312' : '',
							}}
						>
							<button
								type="button"
								className="vertical-nav-item"
								style={{
									// backgroundColor: item.url === apiPage ? '#015f9312' : '',
									// padding: '5px 8px',
									borderRadius: '5px',
									// width: '100%',
								}}
							>
								{item.name}
							</button>
						</AccordionSummary>
						<AccordionDetails
							sx={{
								border: 'none',
								padding: 0,
								backgroundColor: item.url === apiPage ? '#015f9312' : '',
							}}
						>
							{item?.pages?.map((subItem) => (
								<button
									key={subItem.url}
									type="button"
									className="vertical-nav-item vertical-nav-item-2"
									onClick={() =>
										handleNavigate(`/${String(item?.url ?? '')}/${String(subItem?.url ?? '')}`)
									}
									style={{
										backgroundColor: subItem.url === apiSubPage ? '#015f9324' : '',
										padding: '5px 8px',
										borderRadius: '5px',
										width: 'calc(100% - 30px)',
										marginLeft: '30px',
										display: 'flex',
										alignItems: 'center',
									}}
								>
									<span
										className={`api-method api-method-${String(
											subItem?.method?.toLowerCase() ?? 'get',
										)}`}
									/>{' '}
									<span>{subItem.name}</span>
								</button>
							))}
						</AccordionDetails>
					</Accordion>
				);
			})}
		</nav>
	);
};
